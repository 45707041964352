@use "vars";
@use "mixins";
@use "sass:math";
@use "sass:meta";

/**--  作者個人頁  --*/
.pg-novel-author{
  .article_author__title{
    text-align: center;
    @include mixins.font-style(30px,400);
    margin-bottom: 30px;
  }

  @include mixins.media-up(vars.$window-min-width-lg){
    .article_author__title{
      font-size: 48px;
      margin-bottom: 25px;
    }
  }
}

//作者頁首
.view-display-id-b_author_banner{
  margin-bottom: 40px;
  .view-content{
    background: linear-gradient(94deg, #FFB56A 0%, #E55A6D 100%);
    padding: 25px;
  }
}
.pane-views-novel-b-author-novels{
  margin-bottom: 70px;

  .pane-title{
    @include mixins.block-title-deco();
    margin-top: 0;
  }
}



/**-- 作者專區  --*/
.page-novel-authors{
  h1{
    text-align: center;
    @include mixins.font-style(30px,400,normal,null,#000);
    margin-bottom: 25px;
  }

  @include mixins.media-up(vars.$window-min-width-xl){
    h1{
      font-size: 48px;
    }
  }
}
.pane-views-authors-b-all-authors,
.pane-views-novel-b-recommand-novel{
  .pane-title{
    @include mixins.block-title-deco();
  }
}

.pane-views-novel-b-recommand-novel{
  margin-top: 40px;
  margin-bottom: 70px;
  padding-top: 20px;
  border-top: 1px solid #000;
}

