@use "vars";
@use "mixins";
@use "sass:meta";
@use "sass:math";

$cols:2,3;
.grid-list{
  @include mixins.media-down(vars.$window-max-width-md){
    .entity-row{
      margin-bottom: 10px;
    }
  }
  @include mixins.media-up(vars.$window-min-width-md){
    .view-content{
      display: grid;
      gap: 30px;
    }
    @each $col in $cols{
      &.grid-md-col-#{$col} .view-content{
        grid-template-columns: repeat($col,1fr);
      }
    }
  }
  @include mixins.media-up(vars.$window-min-width-lg){
    @each $col in $cols{
      &.grid-lg-col-#{$col} .view-content{
        grid-template-columns: repeat($col,1fr);
      }
    }
  }
  @include mixins.media-up(vars.$window-min-width-xl){
    @each $col in $cols{
      &.grid-xl-col-#{$col} .view-content{
        grid-template-columns: repeat($col,1fr);
        gap: 60px;
      }
    }
  }
}
.flex-grid-list{
  .view-content{
    display: flex;
    flex-wrap: wrap;
  }

  @include mixins.media-up(vars.$window-min-width-md){
    @each $col in $cols{
      &.grid-md-col-#{$col} .entity-row{
        flex:0 0 math.percentage(math.div(1,$col));
      }
    }
  }
  @include mixins.media-up(vars.$window-min-width-lg){
    @each $col in $cols{
      &.grid-lg-col-#{$col} .entity-row{
        flex:0 0 math.percentage(math.div(1,$col));
      }
    }
  }
  @include mixins.media-up(vars.$window-min-width-xl){
    @each $col in $cols{
      &.grid-xl-col-#{$col} .entity-row{
        flex:0 0 math.percentage(math.div(1,$col));
      }
    }
  }
}

.grid-list.use-show-more{
  .view-content.clearfix{
    &:before,&:after{
      content: none;
    }
  }
  .pager-show-more a{
    text-decoration: none;
  }
}

.rank-list{
  .view-content{
    counter-reset:rank 0;
  }
  .views-row{
    counter-increment: rank 1;
    margin-bottom: 10px;
    position: relative;
  }
  .views-row:before{
    z-index: 1;
    content: counter(rank);
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    left: 0;
    top: 0;
    text-align: center;
    @include mixins.font-style(22px,600,30px,null,#fff);
    background: linear-gradient(180deg, #FFB56A 0%, #E55A6D 100%);
  }

}

.pager.tab-style{
  text-align: left;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid vars.$color-primary;
  .pager-item{
    position: relative;
    padding: 7px 0 8px;
    text-align: center;
    background-color: #dedede;
    border-radius: 8px 8px 0 0;
    width: 80px;
    display: block;
    @include mixins.font-style(16px,500,1,0.8px);
  }
  a{
    color: #999;
  }
  .pager-current{
    color: #fff;
    background-color: vars.$color-primary;
  }
  >li{

  }
  a{
    text-decoration: none;
  }
  .ajax-progress-throbber{
    position: absolute;
  }
}

.book-card{
  .entity-title{
    margin-top: 17px;
    a{
      @include mixins.font-style(20px,500,1.5,1px,#000);
      text-decoration: none;
    }
  }
  .entity-cover{
    @include mixins.card-cover(1,math.percentage(math.div(245,377)));
  }
}

.book-card2{
  display: flex;
  align-items: center;
  gap: 13px;
  a{
    text-decoration: none;
  }
  .entity-cover{
    flex: 0 0 160px;
    @include mixins.card-cover(1,math.percentage(math.div(104,160)));
  }
  .entity-info{

  }

  .entity-title{
    @include mixins.font-style(16px,500,1.5,0.8px,vars.$color-primary);
  }
  .entity-body{
    margin-top: 20px;
    text-align: justify;
    @include mixins.font-style(15px,500,1.5,0.75px,#000);
  }
  p{
    margin: 0;
  }

  @include mixins.media-up(vars.$window-min-width-md){
    gap: 48px;
    .entity-cover{
      flex-basis: 273px;
    }
    .entity-title{
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
  }
}

.chapter-card,.chapter-card2{
  position: relative;
  .entity-cover{
    @include mixins.card-cover(1,math.percentage(math.div(245,377)));
    margin-bottom: 17px;
  }
  .entity-title{
    a{
      text-decoration: none;
      @include mixins.font-style(18px,500,1.5,1px,#000);
    }
  }
  .entity-body{
    margin-top: 17px;
    text-align: justify;
    @include mixins.font-style(14px,500,1.5,0.7px,#666);
  }
  .entity-body-inner{
    @include mixins.text-clamp(3);
  }
  p{
    margin: 0;
  }
  @include mixins.media-up(vars.$window-min-width-xl){
    font-size: 20px;
  }
}

.chapter-card2{

}

.author-card{
  .entity-cover{
    text-align: center;
    position: relative;
    padding-bottom: 10px;
    &:before{
      position: absolute;
      content: '';
      display: block;
      height: 70px;
      width: 100%;
      bottom: 0;
      left: 0;
      background: linear-gradient(84deg, #FFB56A 0%, #E55A6D 100%);
    }
    img{
      position: relative;
      border-radius: 50%;
      border: 5px solid #fff;
      width: 180px;
      height: 180px;
      object-fit: cover;
      background-color: #fff;
    }
  }

  .entity-title{
    text-align: center;
    margin-top: 20px;
    a{
      text-decoration: none;
      @include mixins.font-style(24px,500,1,1.2px,#333);
    }

  }
}

.author-intro-card{

  display: flex;
  gap: 30px;
  position: relative;
  align-items: center;
  justify-content: center;

  .entity-cover{
    width: 110px;
    img{
      border-radius: 50%;
    }
  }
  .entity-info{
    max-width: 520px;
  }
  .entity-title{
    margin: 0;
    @include mixins.font-style(32px,500,1,1.6px,#fff)
  }
  .entity-body{
    border-top: 1px solid #fff;
    padding-top: 12px;
    margin-top: 12px;

    @include mixins.font-style(16px,500,1.5,0.8px,#fff)
  }
  .entity-links{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 16px;
  }

  $icons:'fb','ig','twitter','link';

  .entity-link{
    >a{
      position: relative;
      display: inline-block;
      text-indent: -9999px;
      width: 32px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    @each $icon in $icons {
      &.icon--#{$icon}{
        >a{
          background-image: url("../images/icon--#{$icon}.svg");
        }
      }
    }
  }
}

.novel-nav{
  ul.menu{
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 0;
  }
  li{
    list-style: none;
  }
  a,span{
    @include mixins.font-style(20px,400,normal,2px,#333);
    text-decoration: none;
    &.active,&:hover{
      color: vars.$color-primary;
    }
  }

  @include mixins.media-up(vars.$window-min-width-xl){
    ul.menu{
      gap: 50px;
    }
  }
}

.pager-show-more .pager-show-more-next a{
  text-decoration: none;
}

.book-navigation{
  .page-links{
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    border: 0;
    >a{
      padding: 0 20px;
      text-decoration: none;
      @include mixins.font-style(16px,500,26px,0.8px,#000);
      &.disabled{
        color: #ccc;
      }
    }
  }

  a.back-to-book{
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  //override book.css
  .page-next,.page-previous{
    width: initial;
  }
}

i.icon{
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  &.icon--prev{
    background-image: url("../images/icon--arrow-prev.svg");
  }
  &.icon--next{
    background-image: url("../images/icon--arrow-next.svg");
  }
  &.icon--slide-prev{
    background-image: url("../images/icon--slider-arrow-prev.svg");
  }
  &.icon--slide-next{
    background-image: url("../images/icon--slider-arrow-next.svg");
  }
}

.for-mobile{
  @media (min-width: vars.$window-min-width-xl){
    display: none !important;
  }
}
.for-desktop{
  display: none !important;
  @media (min-width: vars.$window-min-width-xl) {
    display: block !important;
  }
}

// tiny slider
.tns-outer{
  position: relative;

  .tns-controls{
    position: absolute;
    height: 1px;
    top: calc(50% - 15px);
    width: 100%;
    button{
      position: absolute;
      padding: 0;
      width: 30px;
      height: 30px;
      border: none;
      background: transparent;
      i{
        width: 100%;
        display: block;
      }
    }
  }
  .tns-inner{
    overflow: hidden;
  }

  button[data-controls="prev"]{
    left: 0;
  }
  button[data-controls="next"]{
    right: 0;
  }

}
