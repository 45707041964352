$window-min-width-sm:576px;
$window-min-width-md:768px;
$window-min-width-lg:992px;
$window-min-width-xl:1200px;
$window-min-width-xxl:1400px;
$window-min-width-xxxl:1600px;

$window-max-width-xsm:330px;
$window-max-width-sm:575px;
$window-max-width-md:767px;
$window-max-width-lg:1023px;
$window-max-width-xl:1199px;
$window-max-width-xxl:1399px;
$window-max-width-xxxl:1599px;

$font-fontawesome:'Font Awesome 5 Free';
$font-noto-sans: 'Noto Sans TC',"微軟正黑體", "Microsoft JhengHei", "STHeiti", Arial,'Helvetica' ,sans-serif;

$color-primary:#e55a6d;