@use "vars";
@use "mixins";
@use "sass:math";
@use "sass:meta";

/**--  最新作品  --*/
.page-novel-latest{
  h1{
    text-align: center;
    @include mixins.font-style(30px,400,normal,null,#000);
    margin-bottom: 25px;
  }

  @include mixins.media-up(vars.$window-min-width-xl){
    h1{
      font-size: 48px;
    }
  }
}

.view-display-id-p_novel_latest{
  .views-title{
    @include mixins.block-title-deco();
  }
}