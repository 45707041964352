@use "vars";
@use "mixins";
@use "sass:math";
@use "sass:meta";

/**-- 小說頁  --**/
.pg-novel-book{
  .book__title{
    text-align: center;
    @include mixins.font-style(30px,400);
    margin-bottom: 30px;
  }

  @include mixins.media-up(vars.$window-min-width-lg){
    .book__title{
      font-size: 48px;
      margin-bottom: 25px;
    }
  }

  .pane-title{
    @include mixins.block-title-deco();
  }
}

//橫幅
.view-display-id-b_book_banner{

}

// 標籤 tags
.view-display-id-b_book_tags{
  .entity-tags {
    ul{
      list-style: none;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
    }
    li{
      background: #dedede;
      margin: 0;
      padding: 3px 8px;
      font-size: .77em;
      line-height: 1.28em;
      border-radius: 9px;

    }
    a{
      color: #000;
      text-decoration: none;
    }
  }

}

//側欄作者
.blk-sidebar-author{
  margin-bottom: 40px;
  .block__title{
    @include mixins.block-title-deco();
  }
}

//作品集數列表
.pane-views-chapter-b-book-chapters{
  margin-top: 50px;
  .pane-title{
    margin-bottom: 40px;
  }
}
.view-display-id-b_book_chapters{
  .views-row{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  .entity-cover{
    flex: 0 0 160px;
    @include mixins.card-cover(1,math.percentage(math.div(176,273)));

  }
  .entity-title{
    @include mixins.font-style(20px,500,1.5,1px);
    a{
      text-decoration: none;
    }
  }
  @include mixins.media-up(vars.$window-min-width-lg){
    .views-row{
      gap: 48px;
    }
    .entity-cover{
      flex-basis: 273px;
    }
  }
}

//其他作品
.pane-views-novel-b-related-book{
  border-top: 1px solid #000;
  margin-top:40px;
  padding-top: 20px;
}
.view-display-id-b_related_book{

  .entity-title{
    a{
      @include mixins.font-style(20px,500,1.5,1px,#000);
      text-decoration: none;
    }
  }
  .entity-cover{
    @include mixins.card-cover(1,math.percentage(math.div(245,377)));
  }

}