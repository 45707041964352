@use "vars";
@use "mixins";
@use "sass:meta";


/*--  小說首頁  --*/
body.novel-home{
  h1{
    text-align: center;
    @include mixins.font-style(30px,400,normal,null,#000);
    margin-bottom: 25px;
  }

  @include mixins.media-up(vars.$window-min-width-xl){
    h1{
      font-size: 48px;
    }
  }

}

.view-display-id-b_novel_carousel{
  margin-bottom: 40px;
}

.pane-views-chapter-b-latest-novel-story,
.pane-views-novel-b-novel-ranking,
.pane-views-chapter-b-novel-18x-articles,
.pane-views-novel-b-novel-latest,
.pane-views-authors-b-novel-home-author{
  .pane-title{
    @include mixins.block-title-deco();
  }
}

.pane-views-chapter-b-latest-novel-story,
.pane-views-novel-b-novel-ranking,
.pane-views-chapter-b-novel-18x-articles,
.pane-views-novel-b-novel-latest{
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
  margin-bottom: 40px;
}

//作者專區
.pane-views-authors-b-novel-home-author{
  margin-bottom: 40px;
  @include mixins.media-up(vars.$window-min-width-md){
    margin-bottom: 70px;
  }
}
