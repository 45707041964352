@use "vars";
@use "mixins";
@use "sass:math";
@use "sass:meta";


/**--  排行榜  --*/
.page-novel-ranking{
  h1{
    text-align: center;
    @include mixins.font-style(30px,400,normal,null,#000);
    margin-bottom: 25px;
  }

  @include mixins.media-up(vars.$window-min-width-xl){
    h1{
      font-size: 48px;
    }
  }
}
//排行榜
.view-display-id-p_novel_ranking{

  .views-title{
    @include mixins.block-title-deco();
  }
  .views-row{
    margin-bottom: 10px;
  }

  @include mixins.media-up(vars.$window-min-width-md){
    .views-row{
      margin-bottom: 20px;
    }
  }
}


.blk-recommand-novel{
  padding-top: 20px;
  border-top: 1px solid #000;

  .block__title{
    @include mixins.block-title-deco();
    margin-bottom: 10px;
  }

}