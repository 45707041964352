@use "vars";
@use "mixins";
@use "sass:math";
@use "sass:meta";

/**--  18禁肉文  --*/
.page-novel-18x-articles{

  ul.tabs{
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }

  h1{
    text-align: center;
    @include mixins.font-style(30px,400,normal,null,#000);
    margin-bottom: 25px;
  }

  @include mixins.media-up(vars.$window-min-width-xl){
    h1{
      font-size: 48px;
    }
  }
}