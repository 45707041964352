@charset "UTF-8";
@media (max-width: 767px) {
  .grid-list .entity-row {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .grid-list .view-content {
    display: grid;
    gap: 30px;
  }
  .grid-list.grid-md-col-2 .view-content {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-list.grid-md-col-3 .view-content {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 992px) {
  .grid-list.grid-lg-col-2 .view-content {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-list.grid-lg-col-3 .view-content {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .grid-list.grid-xl-col-2 .view-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  }
  .grid-list.grid-xl-col-3 .view-content {
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
  }
}
.flex-grid-list .view-content {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .flex-grid-list.grid-md-col-2 .entity-row {
    flex: 0 0 50%;
  }
  .flex-grid-list.grid-md-col-3 .entity-row {
    flex: 0 0 33.3333333333%;
  }
}
@media (min-width: 992px) {
  .flex-grid-list.grid-lg-col-2 .entity-row {
    flex: 0 0 50%;
  }
  .flex-grid-list.grid-lg-col-3 .entity-row {
    flex: 0 0 33.3333333333%;
  }
}
@media (min-width: 1200px) {
  .flex-grid-list.grid-xl-col-2 .entity-row {
    flex: 0 0 50%;
  }
  .flex-grid-list.grid-xl-col-3 .entity-row {
    flex: 0 0 33.3333333333%;
  }
}
.grid-list.use-show-more .view-content.clearfix:before, .grid-list.use-show-more .view-content.clearfix:after {
  content: none;
}

.grid-list.use-show-more .pager-show-more a {
  text-decoration: none;
}

.rank-list .view-content {
  counter-reset: rank 0;
}

.rank-list .views-row {
  counter-increment: rank 1;
  margin-bottom: 10px;
  position: relative;
}

.rank-list .views-row:before {
  z-index: 1;
  content: counter(rank);
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: #fff;
  background: linear-gradient(180deg, #FFB56A 0%, #E55A6D 100%);
}

.pager.tab-style {
  text-align: left;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #e55a6d;
}

.pager.tab-style .pager-item {
  position: relative;
  padding: 7px 0 8px;
  text-align: center;
  background-color: #dedede;
  border-radius: 8px 8px 0 0;
  width: 80px;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.8px;
}

.pager.tab-style a {
  color: #999;
}

.pager.tab-style .pager-current {
  color: #fff;
  background-color: #e55a6d;
}

.pager.tab-style a {
  text-decoration: none;
}

.pager.tab-style .ajax-progress-throbber {
  position: absolute;
}

.book-card .entity-title {
  margin-top: 17px;
}

.book-card .entity-title a {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #000;
  text-decoration: none;
}

.book-card .entity-cover > a {
  position: relative;
  display: block;
  padding-top: 64.9867374005%;
}

.book-card .entity-cover img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.book-card2 {
  display: flex;
  align-items: center;
  gap: 13px;
}

.book-card2 a {
  text-decoration: none;
}

.book-card2 .entity-cover {
  flex: 0 0 160px;
}

.book-card2 .entity-cover > a {
  position: relative;
  display: block;
  padding-top: 65%;
}

.book-card2 .entity-cover img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.book-card2 .entity-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #e55a6d;
}

.book-card2 .entity-body {
  margin-top: 20px;
  text-align: justify;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.75px;
  color: #000;
}

.book-card2 p {
  margin: 0;
}

@media (min-width: 768px) {
  .book-card2 {
    gap: 48px;
  }
  .book-card2 .entity-cover {
    flex-basis: 273px;
  }
  .book-card2 .entity-title {
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
}
.chapter-card, .chapter-card2 {
  position: relative;
}

.chapter-card .entity-cover, .chapter-card2 .entity-cover {
  margin-bottom: 17px;
}

.chapter-card .entity-cover > a, .chapter-card2 .entity-cover > a {
  position: relative;
  display: block;
  padding-top: 64.9867374005%;
}

.chapter-card .entity-cover img, .chapter-card2 .entity-cover img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.chapter-card .entity-title a, .chapter-card2 .entity-title a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #000;
}

.chapter-card .entity-body, .chapter-card2 .entity-body {
  margin-top: 17px;
  text-align: justify;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.7px;
  color: #666;
}

.chapter-card .entity-body-inner, .chapter-card2 .entity-body-inner {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.chapter-card p, .chapter-card2 p {
  margin: 0;
}

@media (min-width: 1200px) {
  .chapter-card, .chapter-card2 {
    font-size: 20px;
  }
}
.author-card .entity-cover {
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}

.author-card .entity-cover:before {
  position: absolute;
  content: "";
  display: block;
  height: 70px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(84deg, #FFB56A 0%, #E55A6D 100%);
}

.author-card .entity-cover img {
  position: relative;
  border-radius: 50%;
  border: 5px solid #fff;
  width: 180px;
  height: 180px;
  object-fit: cover;
  background-color: #fff;
}

.author-card .entity-title {
  text-align: center;
  margin-top: 20px;
}

.author-card .entity-title a {
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.2px;
  color: #333;
}

.author-intro-card {
  display: flex;
  gap: 30px;
  position: relative;
  align-items: center;
  justify-content: center;
}

.author-intro-card .entity-cover {
  width: 110px;
}

.author-intro-card .entity-cover img {
  border-radius: 50%;
}

.author-intro-card .entity-info {
  max-width: 520px;
}

.author-intro-card .entity-title {
  margin: 0;
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.6px;
  color: #fff;
}

.author-intro-card .entity-body {
  border-top: 1px solid #fff;
  padding-top: 12px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.8px;
  color: #fff;
}

.author-intro-card .entity-links {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: 16px;
}

.author-intro-card .entity-link > a {
  position: relative;
  display: inline-block;
  text-indent: -9999px;
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
}

.author-intro-card .entity-link.icon--fb > a {
  background-image: url("../images/icon--fb.svg");
}

.author-intro-card .entity-link.icon--ig > a {
  background-image: url("../images/icon--ig.svg");
}

.author-intro-card .entity-link.icon--twitter > a {
  background-image: url("../images/icon--twitter.svg");
}

.author-intro-card .entity-link.icon--link > a {
  background-image: url("../images/icon--link.svg");
}

.novel-nav ul.menu {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 0;
}

.novel-nav li {
  list-style: none;
}

.novel-nav a, .novel-nav span {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  color: #333;
  text-decoration: none;
}

.novel-nav a.active, .novel-nav a:hover, .novel-nav span.active, .novel-nav span:hover {
  color: #e55a6d;
}

@media (min-width: 1200px) {
  .novel-nav ul.menu {
    gap: 50px;
  }
}
.pager-show-more .pager-show-more-next a {
  text-decoration: none;
}

.book-navigation .page-links {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  border: 0;
}

.book-navigation .page-links > a {
  padding: 0 20px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.8px;
  color: #000;
}

.book-navigation .page-links > a.disabled {
  color: #ccc;
}

.book-navigation a.back-to-book {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.book-navigation .page-next, .book-navigation .page-previous {
  width: initial;
}

i.icon {
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

i.icon.icon--prev {
  background-image: url("../images/icon--arrow-prev.svg");
}

i.icon.icon--next {
  background-image: url("../images/icon--arrow-next.svg");
}

i.icon.icon--slide-prev {
  background-image: url("../images/icon--slider-arrow-prev.svg");
}

i.icon.icon--slide-next {
  background-image: url("../images/icon--slider-arrow-next.svg");
}

@media (min-width: 1200px) {
  .for-mobile {
    display: none !important;
  }
}
.for-desktop {
  display: none !important;
}

@media (min-width: 1200px) {
  .for-desktop {
    display: block !important;
  }
}
.tns-outer {
  position: relative;
}

.tns-outer .tns-controls {
  position: absolute;
  height: 1px;
  top: calc(50% - 15px);
  width: 100%;
}

.tns-outer .tns-controls button {
  position: absolute;
  padding: 0;
  width: 30px;
  height: 30px;
  border: none;
  background: transparent;
}

.tns-outer .tns-controls button i {
  width: 100%;
  display: block;
}

.tns-outer .tns-inner {
  overflow: hidden;
}

.tns-outer button[data-controls=prev] {
  left: 0;
}

.tns-outer button[data-controls=next] {
  right: 0;
}

/*--  小說首頁  --*/
body.novel-home h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  color: #000;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  body.novel-home h1 {
    font-size: 48px;
  }
}
.view-display-id-b_novel_carousel {
  margin-bottom: 40px;
}

.pane-views-chapter-b-latest-novel-story .pane-title,
.pane-views-novel-b-novel-ranking .pane-title,
.pane-views-chapter-b-novel-18x-articles .pane-title,
.pane-views-novel-b-novel-latest .pane-title,
.pane-views-authors-b-novel-home-author .pane-title {
  position: relative;
  padding-left: 16px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #333;
}

.pane-views-chapter-b-latest-novel-story .pane-title:before,
.pane-views-novel-b-novel-ranking .pane-title:before,
.pane-views-chapter-b-novel-18x-articles .pane-title:before,
.pane-views-novel-b-novel-latest .pane-title:before,
.pane-views-authors-b-novel-home-author .pane-title:before {
  content: "";
  display: block;
  height: 1em;
  width: 5px;
  background-color: #e55a6d;
  position: absolute;
  left: 0;
}

.pane-views-chapter-b-latest-novel-story,
.pane-views-novel-b-novel-ranking,
.pane-views-chapter-b-novel-18x-articles,
.pane-views-novel-b-novel-latest {
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
  margin-bottom: 40px;
}

.pane-views-authors-b-novel-home-author {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .pane-views-authors-b-novel-home-author {
    margin-bottom: 70px;
  }
}
/**-- 小說頁  --**/
.pg-novel-book .book__title {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .pg-novel-book .book__title {
    font-size: 48px;
    margin-bottom: 25px;
  }
}
.pg-novel-book .pane-title {
  position: relative;
  padding-left: 16px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #333;
}

.pg-novel-book .pane-title:before {
  content: "";
  display: block;
  height: 1em;
  width: 5px;
  background-color: #e55a6d;
  position: absolute;
  left: 0;
}

.view-display-id-b_book_tags .entity-tags ul {
  list-style: none;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.view-display-id-b_book_tags .entity-tags li {
  background: #dedede;
  margin: 0;
  padding: 3px 8px;
  font-size: 0.77em;
  line-height: 1.28em;
  border-radius: 9px;
}

.view-display-id-b_book_tags .entity-tags a {
  color: #000;
  text-decoration: none;
}

.blk-sidebar-author {
  margin-bottom: 40px;
}

.blk-sidebar-author .block__title {
  position: relative;
  padding-left: 16px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #333;
}

.blk-sidebar-author .block__title:before {
  content: "";
  display: block;
  height: 1em;
  width: 5px;
  background-color: #e55a6d;
  position: absolute;
  left: 0;
}

.pane-views-chapter-b-book-chapters {
  margin-top: 50px;
}

.pane-views-chapter-b-book-chapters .pane-title {
  margin-bottom: 40px;
}

.view-display-id-b_book_chapters .views-row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.view-display-id-b_book_chapters .entity-cover {
  flex: 0 0 160px;
}

.view-display-id-b_book_chapters .entity-cover > a {
  position: relative;
  display: block;
  padding-top: 64.4688644689%;
}

.view-display-id-b_book_chapters .entity-cover img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.view-display-id-b_book_chapters .entity-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
}

.view-display-id-b_book_chapters .entity-title a {
  text-decoration: none;
}

@media (min-width: 992px) {
  .view-display-id-b_book_chapters .views-row {
    gap: 48px;
  }
  .view-display-id-b_book_chapters .entity-cover {
    flex-basis: 273px;
  }
}
.pane-views-novel-b-related-book {
  border-top: 1px solid #000;
  margin-top: 40px;
  padding-top: 20px;
}

.view-display-id-b_related_book .entity-title a {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #000;
  text-decoration: none;
}

.view-display-id-b_related_book .entity-cover > a {
  position: relative;
  display: block;
  padding-top: 64.9867374005%;
}

.view-display-id-b_related_book .entity-cover img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

/**--  18禁肉文  --*/
.page-novel-18x-articles ul.tabs {
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
}

.page-novel-18x-articles h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  color: #000;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .page-novel-18x-articles h1 {
    font-size: 48px;
  }
}
/**--  作者個人頁  --*/
.pg-novel-author .article_author__title {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .pg-novel-author .article_author__title {
    font-size: 48px;
    margin-bottom: 25px;
  }
}
.view-display-id-b_author_banner {
  margin-bottom: 40px;
}

.view-display-id-b_author_banner .view-content {
  background: linear-gradient(94deg, #FFB56A 0%, #E55A6D 100%);
  padding: 25px;
}

.pane-views-novel-b-author-novels {
  margin-bottom: 70px;
}

.pane-views-novel-b-author-novels .pane-title {
  position: relative;
  padding-left: 16px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #333;
  margin-top: 0;
}

.pane-views-novel-b-author-novels .pane-title:before {
  content: "";
  display: block;
  height: 1em;
  width: 5px;
  background-color: #e55a6d;
  position: absolute;
  left: 0;
}

/**-- 作者專區  --*/
.page-novel-authors h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  color: #000;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .page-novel-authors h1 {
    font-size: 48px;
  }
}
.pane-views-authors-b-all-authors .pane-title,
.pane-views-novel-b-recommand-novel .pane-title {
  position: relative;
  padding-left: 16px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #333;
}

.pane-views-authors-b-all-authors .pane-title:before,
.pane-views-novel-b-recommand-novel .pane-title:before {
  content: "";
  display: block;
  height: 1em;
  width: 5px;
  background-color: #e55a6d;
  position: absolute;
  left: 0;
}

.pane-views-novel-b-recommand-novel {
  margin-top: 40px;
  margin-bottom: 70px;
  padding-top: 20px;
  border-top: 1px solid #000;
}

/**--  排行榜  --*/
.page-novel-ranking h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  color: #000;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .page-novel-ranking h1 {
    font-size: 48px;
  }
}
.view-display-id-p_novel_ranking .views-title {
  position: relative;
  padding-left: 16px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #333;
}

.view-display-id-p_novel_ranking .views-title:before {
  content: "";
  display: block;
  height: 1em;
  width: 5px;
  background-color: #e55a6d;
  position: absolute;
  left: 0;
}

.view-display-id-p_novel_ranking .views-row {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .view-display-id-p_novel_ranking .views-row {
    margin-bottom: 20px;
  }
}
.blk-recommand-novel {
  padding-top: 20px;
  border-top: 1px solid #000;
}

.blk-recommand-novel .block__title {
  position: relative;
  padding-left: 16px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #333;
  margin-bottom: 10px;
}

.blk-recommand-novel .block__title:before {
  content: "";
  display: block;
  height: 1em;
  width: 5px;
  background-color: #e55a6d;
  position: absolute;
  left: 0;
}

/**--  最新作品  --*/
.page-novel-latest h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  color: #000;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .page-novel-latest h1 {
    font-size: 48px;
  }
}
.view-display-id-p_novel_latest .views-title {
  position: relative;
  padding-left: 16px;
  font-size: 26px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1.3px;
  color: #333;
}

.view-display-id-p_novel_latest .views-title:before {
  content: "";
  display: block;
  height: 1em;
  width: 5px;
  background-color: #e55a6d;
  position: absolute;
  left: 0;
}

.page-novel-chapter .top-book-nav {
  margin-top: 30px;
}

.page-novel-tags h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  color: #000;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .page-novel-tags h1 {
    font-size: 48px;
  }
}