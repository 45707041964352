@use "vars";
@use "sass:math";
@use "sass:meta";
@use "func" as func;

@mixin text-clamp($clamp){
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:$clamp;
}

@mixin font-style($font-size:'',$weight:null,$line_h:null,$letter-spacing:null,$color:null){
  @if not func.is_empty($font-size){
    font-size: $font-size;
  }
  @if not func.is_empty($weight) {
    font-weight: $weight;
  }
  @if not func.is_empty($line_h) {
    line-height: $line_h;
  }
  @if not func.is_empty($letter-spacing) {
    letter-spacing: $letter-spacing;
  }
  @if not func.is_empty($color) {
    color: $color;
  }
}

@mixin card-cover($with-link:null,$padding-top:100%) {
  @if not func.is_empty($with-link) {
    > a {
      position: relative;
      display: block;
      @if $padding-top {
        padding-top: $padding-top;
      }
    }
  } @else {
    position: relative;
    @if $padding-top {
      padding-top: $padding-top;
    }
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

@mixin full-width(){
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@mixin mx-auto($width:null,$col:null,$gap:null){
  margin-right: auto;
  margin-left: auto;
  @if $gap and $col and $width{
    max-width: $width * $col + $gap * ($col - 1);
  }@else if $width {
    max-width: $width;
  }

}

@mixin limiter($plr_m:20px,$plr_d:40px) {
  margin-left: auto;
  margin-right: auto;
  padding-left: $plr_m;
  padding-right: $plr_m;
  @media (min-width: vars.$window-min-width-sm) {
    padding-left: $plr_d;
    padding-right: $plr_d;
  }
  @media (min-width: vars.$window-min-width-md) {
    max-width: 720px;
    padding-left: 0;
    padding-right: 0;
  }
  @media (min-width: vars.$window-min-width-lg) {
    max-width: 960px;
  }
  @media (min-width: vars.$window-min-width-xl) {
    max-width: 1200px;
  }
}

@mixin icon-bg-btn($icon-name,$size:null){
  content: '';
  background: url("../images/icon--#{$icon-name}.svg") center no-repeat;
  background-size: contain;
  text-indent: -9999px;
  text-align: left;
  display: block;
  @if($size){
    width: $size;
    height: $size;
  }
}

@mixin bt-row-margin(){
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

@mixin media-up($width){
  @media (min-width: $width) {
    @content;
  }
}

@mixin media-down($width){
  @media (max-width: $width) {
    @content;
  }
}

@mixin media-between($min-width,$max-width){
  @media (min-width: #{$min-width} ) and (max-width: #{$max-width}) {
    @content;
  }
}

@mixin block-title-deco(){
  position: relative;
  padding-left: 16px;
  @include font-style(26px,400,1,1.3px,#333);
  &:before{
    content: '';
    display: block;
    height: 1em;
    width: 5px;
    background-color: vars.$color-primary;
    position: absolute;
    left: 0;
  }
}